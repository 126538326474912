<template>
  <div style="height: 100vh">
    <div class="logo" @click="$router.push('/')" style="width: 256px">
      <img :src="logo" width="36" style="margin-top: -6px; margin-left: 8px" />
      <span v-if="!collapsed" style="color: #1890ff; margin-left: 24px; font-size: 16px">盒木CRM</span>
      <span v-if="!collapsed" style="color: #1890ff; margin-left: 6px">
        <span style="font-size: 12px">V1.0</span>
      </span>
    </div>

    <a-menu
      theme="light"
      mode="inline"
      :selectedKeys="selectedKeys"
      :inline-collapsed="collapsed"
      :style="{ width: collapsed ? '80px' : '256px' }"
      @click="switchView"
    >
      <a-menu-item key="/home">
        <a-icon type="home" />
        <span>首页</span>
      </a-menu-item>
      <a-menu-item key="/clue">
        <a-icon type="tag" />
        <span>线索管理</span>
      </a-menu-item>
      <a-menu-item key="/public_clue">
        <a-icon type="tags" />
        <span>公海客户</span>
      </a-menu-item>
      <!-- <a-menu-item key="/application">
        <a-icon type="book" />
        <span>客户账号申请</span>
      </a-menu-item> -->
      <a-menu-item v-if="isManager" key="/account">
        <a-icon type="audit" />
        <span>客户账号管理</span>
      </a-menu-item>
      <a-menu-item v-if="isManager" key="/user">
        <a-icon type="user" />
        <span>用户管理</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
export default {
  props: ["collapsed"],
  data() {
    return {
      selectedKeys: [],
      logo: require("@/assets/logo.png"),
    };
  },
  computed: {
    isManager() {
      return this.$store.state.user.isManager;
    },
  },
  methods: {
    switchView(item) {
      if (this.$route.path !== item.key) {
        this.$router.push(item.key);
      }
    },
  },
  watch: {
    $route(to) {
      this.selectedKeys = [to.path];
    },
  },
  mounted() {
    this.selectedKeys = [this.$route.path];
  },
};
</script>

<style scoped>
.logo {
  font-weight: bold;
  font-size: 20px;
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #6662;
  cursor: pointer;
}

.ant-menu-inline {
  border-right: 0px !important;
}
</style>
